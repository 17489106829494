
import RecordingsEventsList from "@/components/recordings/events/RecordingsEventsList"
import EventsUtils from "@/components/gateReport/mixins/eventsUtils"
import { AnalyticsEvent, GateReportEventType } from "@evercam/shared/types"
import { useGateReportStore } from "@/stores/gateReport"
import { useRecordingsStore } from "@/stores/recordings"
import { useEventsStore } from "@/stores/events"
import { useCameraStore } from "@/stores/camera"
import { mapStores } from "pinia"

export default {
  name: "RecordingsEvents",
  components: {
    RecordingsEventsList,
  },
  mixins: [EventsUtils],
  data() {
    return {
      sidePanels: [0],
    }
  },
  computed: {
    ...mapStores(
      useGateReportStore,
      useRecordingsStore,
      useEventsStore,
      useCameraStore
    ),
  },
  watch: {
    "recordingsStore.selectedTimestamp": {
      immediate: true,
      async handler(val, previousVal) {
        const newTimestamp = this.$moment(val)
        const previousTimestamp = this.$moment(previousVal)

        const isNewDay =
          newTimestamp.format("YYYY-MM-DD") !==
          previousTimestamp.format("YYYY-MM-DD")

        if (!isNewDay && !!previousVal) {
          this.updateFilteredEvents()

          return
        }
        this.eventsStore.filteredEvents = []
        await this.fetchGateReportEvents()

        this.$setTimeout(this.updateFilteredEvents, 500)
      },
    },
  },
  mounted() {
    this.gateReportStore.fetchROIs()
    this.eventsStore.fetchEventsCounts()
  },
  methods: {
    onPanelHeaderClick() {
      this.$analytics.saveEvent(
        AnalyticsEvent.RecordingToggleGateReportEvents,
        {
          showGateReportEvents: !this.sidePanels.includes(0),
        }
      )
    },
    async fetchGateReportEvents() {
      let selectedDate = this.$moment(
        this.recordingsStore.selectedTimestamp || new Date()
      ).format("YYYY-MM-DD")
      await this.eventsStore.fetchEventsByDate({
        fromDate: this.$moment
          .tz(
            `${selectedDate}T00:00:00`,
            this.cameraStore.selectedCameraTimezone
          )
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss"),
        toDate: this.$moment
          .tz(
            `${selectedDate}T23:59:59`,
            this.cameraStore.selectedCameraTimezone
          )
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss"),
      })
    },
    getProcessedEventItem(event) {
      return {
        eventTime: event.eventTime,
        id: event.id,
        truckType: event.truckType,
        roiId: event.roiId,
        type: this.$t(
          `content.events.${
            event.eventType === GateReportEventType.Arrived ? "in" : "out"
          }`
        ),
        thumbnailUrl: this.getEventThumbnailUrl(event),
        label: this.getLabel(event),
      }
    },
    getLabel(event) {
      return `${this.gateReportStore.getVehicleLabel(event.truckType)} (${
        event.eventType
      })`
    },
    updateFilteredEvents() {
      if (!this.eventsStore.events) {
        this.eventsStore.filteredEvents = []

        return
      }

      const minTime = this.$moment(
        this.recordingsStore.selectedTimestamp
      ).startOf("hour")
      const maxTime = this.$moment(
        this.recordingsStore.selectedTimestamp
      ).endOf("hour")
      const timezone = this.cameraStore.selectedCameraTimezone
      this.eventsStore.filteredEvents = this.eventsStore.events
        .reduce((acc, event) => {
          const eventTime = this.$moment.tz(event.eventTime, timezone)
          if (
            event.cameraExid === this.cameraStore.selectedCameraExid &&
            eventTime.isBefore(maxTime) &&
            eventTime.isAfter(minTime)
          ) {
            return [
              ...acc,
              {
                ...event,
                ...this.getProcessedEventItem(event),
              },
            ]
          }

          return acc
        }, [])
        .sort((a, b) => {
          return new Date(a.eventTime).getTime() >
            new Date(b.eventTime).getTime()
            ? 1
            : -1
        })
    },
  },
}
