
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import {
  MediaCreateRequestPayload,
  MediaType,
} from "@evercam/shared/types/media"
import {
  SnapshotRangeRequestPayload,
  SnapshotRangeResponsePayload,
} from "@evercam/shared/types/recording"
import Vue from "vue"
import { NvrModel, EvercamGoFeatures } from "@evercam/shared/types"
import { downloadFile } from "@evercam/shared/utils"
import { ExNvrApi } from "@evercam/shared/api/exNvrApi"
import { mapStores } from "pinia"
import { useRecordingsStore } from "@/stores/recordings"
import { useMediaHubStore } from "@/stores/mediaHub"
import { useCameraStore } from "@/stores/camera"
import { useProjectStore } from "@/stores/project"
import { useGoStore } from "@/stores/go"
import { AnalyticsEvent } from "@evercam/shared/types/analytics"
import { ECol } from "@evercam/ui"
import { ProjectFeatureFlag } from "@evercam/shared/types/project"
import { CameraStatus } from "@evercam/shared/types/camera"
export default Vue.extend({
  name: "RecordingsExportDialog",
  components: {
    ECol,
  },
  props: {
    selectedTimestamp: {
      type: [String, Date, Object],
      default: new Date().toISOString(),
    },
    isEdgeVideo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: null,
      time: null,
      dialog: false,
      dateDialog: false,
      dateFormatted: "",
      clipType: MediaType.Clip,
      clipTypes: MediaType,
      title: "",
      duration: 30,
      totalSnapshots: 0,
      totalSeconds: 0,
      hasSnapshot: true,
      timePickerMenu: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapStores(
      useMediaHubStore,
      useRecordingsStore,
      useCameraStore,
      useProjectStore,
      useGoStore
    ),
    isUnderNda() {
      return this.projectStore.selectedProject?.featureFlags?.includes(
        ProjectFeatureFlag.NdaMc
      )
    },
    timezone() {
      return this.cameraStore.selectedCameraTimezone
    },
    showFps() {
      return this.cameraStore.selectedCamera?.nvrModel !== NvrModel.ExNvr
    },
    isLocalClip() {
      return this.clipType === "local_clip"
    },
    canExportLocalClips() {
      if (this.isUnderNda) {
        return this.cameraStore.isCameraHasSharingRights
      }

      return true
    },
    canShowClipOptions() {
      return (
        !this.isEdgeVideo &&
        this.cameraStore.selectedCamera?.nvrModel === NvrModel.ExNvr &&
        this.cameraStore.selectedCamera.status !== CameraStatus.Decommissioned
      )
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        return
      }

      this.initModels()
      this.getClipInfo()
    },
    date() {
      this.dateFormatted = this.$moment(this.date).format("DD/MM/YYYY")
      this.getClipInfo()
    },
    duration() {
      this.getClipInfo()
    },
    time() {
      this.getClipInfo()
    },
    clipType(value, oldValue) {
      if (this.$permissions.project.has.go() && value === MediaType.LocalClip) {
        this.goStore.openDialog(EvercamGoFeatures.LocalRecordings)
        this.$nextTick(() => {
          this.clipType = oldValue
          this.updateClipDuration()
        })
      }
    },
  },
  methods: {
    initModels() {
      let selectedTimestamp = this.$moment.tz(
        this.selectedTimestamp,
        this.cameraStore.selectedCameraTimezone
      )
      let snapshotTime = selectedTimestamp.format("HH:mm")
      if (snapshotTime == "00:00") {
        this.date = selectedTimestamp.subtract(1, "d").format("YYYY-MM-DD")
      } else {
        this.date = selectedTimestamp.format("YYYY-MM-DD")
      }
      this.dateFormatted = this.$moment(this.date).format("DD/MM/YYYY")
      this.title = `${this.cameraStore.selectedCamera.name}--${this.$moment()
        .tz(this.cameraStore.selectedCamera.timezone)
        .format("Do MMM--HH:mm")}`
      this.time = this.$moment
        .tz(this.selectedTimestamp, this.cameraStore.selectedCamera.timezone)
        .subtract(this.duration, "m")
        .format("HH:mm")
    },
    onCancelCreateClip() {
      this.$emit("toggle-export-dialog", "cancel")
      this.dialog = false
    },
    updateClipDuration() {
      if (this.isLocalClip) {
        this.duration = 1
      } else {
        this.duration = 30
      }
    },
    async downloadEdgeFootage(startDate, duration) {
      try {
        this.isLoading = true
        const data = await ExNvrApi.devices.downloadFootage({
          apiUrl: this.recordingsStore.edgeStreamingConfig?.apiUrl,
          deviceId: this.recordingsStore.edgeStreamingConfig?.deviceId,
          token: this.recordingsStore.edgeStreamingToken,
          startDate: this.$moment(startDate).format("YYYY-MM-DDTHH:mm:ssZ"),
          duration,
        })
        const filename = `${
          this.cameraStore.selectedCamera.name
        }--${this.$moment(startDate).format("Do MMM--HH:mm")}.mp4`
        const url = URL.createObjectURL(new Blob([data]))

        downloadFile(url, filename)

        this.$setTimeout(() => {
          URL.revokeObjectURL(url)
        }, 100)

        this.dialog = false
      } catch (e) {
        console.error(e)
        this.$notifications.error(
          "Failed to download footage for the specified period",
          e
        )
      } finally {
        this.isLoading = false
      }
    },
    createMedia() {
      let from = this.$moment.tz(`${this.date} ${this.time}`, this.timezone)
      let to = from.clone().add(this.duration, "m")

      if (this.isEdgeVideo) {
        this.downloadEdgeFootage(from, this.duration)

        return
      }

      const payload: MediaCreateRequestPayload = {
        title: this.title,
        cameraExid: this.cameraStore.selectedCameraExid,
        fromDate: from.toISOString(true),
        toDate: to.toISOString(true),
        type: this.clipType,
      }

      EvercamApi.mediaHub
        .cCreate(this.projectStore.selectedProjectExid, payload)
        .then(() => {
          this.mediaHubStore.forceReloadMediaHub()
          this.$notifications.success("Clip created successfully")
          this.$analytics.saveEvent(AnalyticsEvent.RecordingsCreateClip)
          this.$emit("media-created")
        })
        .catch((error) => {
          this.$notifications.error({
            text: this.$t("content.media_hub.create_failed"),
            error,
          })
        })
        .finally(() => {
          this.dialog = false
          this.$emit("toggle-export-dialog", "close")
        })
    },
    getClipInfo() {
      let fromDate = this.$moment.tz(`${this.date} ${this.time}`, this.timezone)
      let toDate = fromDate.clone().add(this.duration, "m")
      let payload: SnapshotRangeRequestPayload = {
        from: fromDate.toISOString(true),
        to: toDate.toISOString(true),
        limit: 3600,
        page: 1,
      }
      EvercamApi.recordings
        .getSnapshotRange(`${this.cameraStore.selectedCameraExid}`, payload)
        .then((response: SnapshotRangeResponsePayload) => {
          this.totalSnapshots = response.snapshots.length
          this.totalSeconds = Math.round(this.totalSnapshots / 6)
          this.hasSnapshot = this.totalSnapshots > 0
        })
        .catch((err) => {
          this.$errorTracker.save(err)
        })
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split("/")

      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
    updateTime(time) {
      this.$refs.startTimeMenu.save(time)
    },
  },
})
